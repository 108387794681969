<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>健康汇管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box" v-if="this.$store.state.role === 'admin'">
        <div class="input_box">
          <span class="level_box">活动类型：</span>
          <el-select class="picker_box" v-model="searchForm.activityType" clearable style="width: 100%;">
            <el-option label="本地活动" value="1" />
            <el-option label="优惠政策" value="2" />
            <el-option label="优惠服务" value="3" />
          </el-select>
        </div>
        <div class="input_box">
          <span>活动标题：</span>
          <el-input class="picker_box" v-model="searchForm.title" placeholder="请输入活动标题" clearable
            @keyup.enter.native="handleQuery" />
        </div>
        <div class="input_box">
          <span>开始时间：</span>
          <el-date-picker clearable v-model="searchForm.startDate" type="date" value-format="yyyy-MM-dd"
            placeholder="请选择开始时间" :picker-options="startPickerOptions" />
        </div>
        <div class="input_box">
          <span>结束时间：</span>
          <el-date-picker clearable v-model="searchForm.endDate" type="date" value-format="yyyy-MM-dd"
            placeholder="请选择结束时间" :picker-options="overPickerOptions" />
        </div>
        <div class="input_box">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        </div>
        <div class="input_box">
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </div>
      </div>
      <div class="operate_btn">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
        <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple"
          @click="handleDelete">删除</el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table v-loading="loading" :data="discountList" @selection-change="handleSelectionChange" border
        style="width: 100%" height="100%">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="活动类型" align="center" prop="activityType" width="75">
          <template slot-scope="scope">
            <p v-show="scope.row.activityType == 1">本地活动</p>
            <p v-show="scope.row.activityType == 2">优惠政策</p>
            <p v-show="scope.row.activityType == 3">优惠服务</p>
          </template>
        </el-table-column>
        <el-table-column label="活动标题" align="center" prop="title" />
        <el-table-column label="描述内容" align="center" prop="description" />
        <el-table-column label="活动内容" align="center" prop="content">
          <template slot-scope="scope">
            <span style="cursor: pointer;color:#409EFF" @click="viewOriginal(scope.row)">查看原文</span>
          </template>
        </el-table-column>
        <el-table-column label="图片" align="center" prop="imageUrl">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.imageUrl" fit="scale-down" />
          </template>
        </el-table-column>
        <el-table-column label="开始时间" align="center" prop="startDate" width="120" />
        <el-table-column label="结束时间" align="center" prop="endDate" width="120" />
        <el-table-column label="创建时间" align="center" prop="createdTime" />
        <el-table-column label="创建人" align="center" prop="createdBy" />
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-share" @click="detailListdiolog(scope.row)">详情
            </el-button>
            <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
            <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!-- 查看原文 -->
    <el-dialog :visible.sync="dialogVisible" class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>原文
      </div>
      <div class="ql-container ql-snow">
        <div class="ql-editor" v-html="contentDetail" />
      </div>
    </el-dialog>

    <!-- 添加或修改活动对话框 -->
    <el-dialog :visible.sync="open" ref="form" width="50%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ dialogtitle }}
      </div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="活动标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入活动标题" />
        </el-form-item>
        <el-form-item label="描述内容" prop="description">
          <el-input v-model="form.description" placeholder="请输入描述内容" />
        </el-form-item>
        <el-form-item label="活动内容" prop="content">
          <editor v-model="form.content" :min-height="192" />
        </el-form-item>
        <el-form-item label="活动类型" prop="activityType">
          <el-select v-model="form.activityType" clearable style="width: 100%;">
            <el-option label="本地活动" value="1" />
            <el-option label="优惠政策" value="2" />
            <el-option label="优惠服务" value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="活动图片" prop="imageUrl">
          <el-upload class="avatar-uploader" action accept=".jpg, .png" :show-file-list="false"
            :http-request="uploadImg">
            <img width="320" height="240" v-if="form.imageUrl" :src="form.imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="开始时间" prop="startDate">
          <el-date-picker clearable v-model="form.startDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择开始时间"
            :picker-options="beginPickerOptions" />
        </el-form-item>
        <el-form-item label="结束时间" prop="endDate">
          <el-date-picker clearable v-model="form.endDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择结束时间"
            :picker-options="endPickerOptions" />
        </el-form-item>
        <div style="display: flex" v-if="this.$store.state.role === 'admin'">
          <el-form-item label="社区选择" style="flex: 1">
            <el-select v-model="areaCode" clearable @change="getAreaCode" filterable placeholder="仅供筛选社区">
              <el-option v-for="item in options" :key="item.regionCode" :value="item.regionCode" :label="item.area" />
            </el-select>
          </el-form-item>
          <el-form-item prop="regionCode" style="flex: 1;margin-left: 10px" label-width="0">
            <el-select v-model="form.regionCode" filterable>
              <el-option :label="item.name" :value="item.id" v-for="item in communityList" :key="item.id" />
            </el-select>
          </el-form-item>
        </div>
        <!-- <el-form-item label="社区" prop="regionCode" placeholder="请选择..." v-if="this.$store.state.role === 'admin'">
          <el-select v-model="form.regionCode" clearable placeholder="社区">
            <el-option v-for="item in  communityList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 详情页面-->
    <el-dialog :visible.sync="openDetailList" width="50%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ detailListTitle }}
      </div>
      <div class="reserveTitle">
        <p>{{ discountTitle }} </p>
        <p style="font-size: 12px; margin: 10px">{{ discountDescription }}</p>
      </div>
      <template>
        <el-table :data="appointmentList" style="width: 90%;margin:0 5%;" height="600">
          <el-table-column prop="userName" label="预约人名" width="120">
          </el-table-column>
          <el-table-column prop="userPhone" label="预约人联系方式" width="120">
          </el-table-column>
          <el-table-column prop="appointmentTime" label="预约时间" width="120">
          </el-table-column>
          <el-table-column prop="applyTime" label="参加时间" width="120">
          </el-table-column>
          <el-table-column prop="appointmentCode" label="预约编码" width="120">
          </el-table-column>
          <el-table-column prop="status" label="预约状态" width="120">
            <template slot-scope="scope">
              {{ scope.row.status == 1 ? "已完成" : "进行中" }}
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button size="mini" type="text" icon="el-icon-share" @click="cancelbtu(scope.row)">取消
              </el-button>
              <el-button size="mini" type="text" icon="el-icon-edit" @click="completebtu(scope.row)"
                :disabled="scope.row.status == 1">完成</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import api from '@/api/index'
import { uploadFile } from "@/api/uploadFile";

export default {
  name: 'Discount',
  data() {
    return {
      startPickerOptions: {
        disabledDate: (time) => {
          if (this.searchForm.endDate) {
            const endTime = new Date(this.searchForm.endDate);
            return time.getTime() >= endTime.getTime()
          } else {
            return false
          }
        },
      },
      overPickerOptions: {
        disabledDate: (time) => {
          if (this.searchForm.startDate) {
            const beginTime = new Date(this.searchForm.startDate);
            return time.getTime() <= beginTime.getTime();
          } else {
            return false
          }
        },
      },
      beginPickerOptions: {
        disabledDate: (time) => {
          if (this.form.endDate) {
            const endTime = new Date(this.form.endDate);
            return time.getTime() >= endTime.getTime() || time.getTime() < (new Date().getTime() - 24 * 60 * 60 * 1000);
          } else {
            // 手动选择可选择今天 
            return time.getTime() < (new Date().getTime() - 24 * 60 * 60 * 1000);
          }
        },
      },
      endPickerOptions: {
        disabledDate: (time) => {
          if (this.form.startDate) {
            const beginTime = new Date(this.form.startDate);
            return time.getTime() <= beginTime.getTime();
          } else {
            return time.getTime() < Date.now();
          }
        },
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 活动表格数据
      discountList: [],
      // 弹出层标题
      dialogtitle: '',
      // 是否显示弹出层
      open: false,
      openDetailList: false,
      detailListTitle: '',
      dialogImageUrl: '',
      // 预约表
      appointmentList: [],
      communityList: [],
      // 登录用户名
      username: '',
      // 登录用户id
      userId: 0,
      // 活动标题
      discountTitle: '',
      // 活动描述
      discountDescription: '',
      //社区id
      communityIds: null,
      //是否为管理员
      isAdmin: false,

      Data: {
        regionCode: '',
      },
      //查询参数
      searchForm: {
        id: null,
        hospitalId: null,
        activityType: null,
        title: null,
        content: null,
        description: null,
        imageUrl: null,
        videoUrl: null,
        startDate: null,
        endDate: null,
        regionCode: null,
        createdBy: null,
        createdTime: null,
        communityIds: null
      },
      //分页参数
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      // 表单参数
      form: {
        id: null,
        hospitalId: null,
        activityType: null,
        title: null,
        content: null,
        description: null,
        imageUrl: null,
        videoUrl: null,
        startDate: null,
        endDate: null,
        regionCode: null,
        createdBy: null,
        createdTime: null,
        communityIds: null
      },
      // 表单校验
      rules: {
        title: [
          { required: true, message: '活动标题不能为空', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '描述内容不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '活动内容不能为空', trigger: 'blur' }
        ],
        activityType: [
          { required: true, message: '活动类型不能为空', trigger: 'blur' }
        ],
        startDate: [
          { required: true, message: '活动开始时间不能为空', trigger: 'blur' }
        ],
        endDate: [
          { required: true, message: '活动结束时间不能为空', trigger: 'blur' }
        ],
        regionCode: [
          { required: true, message: '社区不能为空', trigger: 'blur' }
        ],
        imageUrl: [
          { required: true, message: '图片不能为空', trigger: 'blur' }
        ]
      },
      contentDetail: null,
      dialogVisible: false,
      areaCode: null,
      options: []
    }
  },
  created() {
    this.getUser()
    this.fetchUserPower()
    this.options = this.$store.state.userArea
  },
  methods: {
    getAreaCode(val) {
      //const areaCode = this.$refs.myCascader.getCheckedNodes()[0].value
      // this.ruleForm.regionCode=areaCode
      this.form.regionCode = null
      this.getCommuintyList(val)
    },
    viewOriginal(row) {
      this.contentDetail = row.content;
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getList()
    },

    //获取用户信息
    async getUser() {
      try {
        const res = await api.getUserInfo()
        if (res.code == 200) {
          this.isAdmin = res.user.admin
          this.username = res.user.userName
          this.userId = res.user.userId
        } else {
          this.username = ''
          this.$message({
            message: res.msg,
            type: error
          })
        }
      } catch (error) {
        this.username = ''
        this.$message.error(error)
      }
      console.log(this.username);
    },

    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.communityIds = res.data.comId[0]
            this.searchForm.communityIds = res.data.comId
            console.log(this.communityIds);
          } else {
            this.Data = {}
          }
        }
        this.getList()
      } catch (e) {

      }
    },

    /** 查询活动列表 */
    async getList() {
      this.loading = true
      try {
        const response = await api.listDiscount(this.searchForm, this.pageBox)
        if (response.code == 200) {
          this.discountList = response.rows
          this.pageBox.total = response.total
        } else {
          this.$message.error(response.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      } finally {
        this.loading = false
      }

    },

    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      const form = {
        id: null,
        hospitalId: null,
        activityType: null,
        title: null,
        content: null,
        description: null,
        imageUrl: null,
        videoUrl: null,
        startDate: null,
        endDate: null,
        regionCode: null,
        createdBy: null,
        createdTime: null,
        communityIds: null
      }
      this.form = form
      this.areaCode = null;
      console.log(this.form);
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.pageBox.pageNum = 1
      this.getList()

    },
    /** 重置按钮操作 */
    resetQuery() {
      //查询参数
      this.searchForm = {
        id: null,
        hospitalId: null,
        activityType: null,
        title: null,
        content: null,
        description: null,
        imageUrl: null,
        videoUrl: null,
        startDate: null,
        endDate: null,
        regionCode: null,
        createdBy: null,
        createdTime: null,
        communityIds: null
      };
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.dialogtitle = '添加活动'
      console.log("this.communityIds", this.communityIds);
      if (this.$store.state.role !== 'admin') {
        this.form.regionCode = this.communityIds
      }
      this.open = true
    },
    /** 修改按钮操作 */
    async handleUpdate(row) {
      this.reset()
      const id = row.id || this.ids
      this.getCommuintyList()
      let res = await api.getDiscount(id)
      let form = res.data
      form.regionCode = Number(res.data.regionCode)
      this.html = form.content
      this.form = form
      this.dialogtitle = '修改活动'
      this.open = true
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            api.updateDiscount(this.form).then(response => {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.open = false
              this.getList()
            })
          } else {
            this.form.createdBy = this.username
            this.form.doctorId = this.userId
            //this.form.deleted = 0
            api.addDiscount(this.form).then(response => {
              this.$message({
                message: '新增成功',
                type: 'success'
              })
              this.open = false
              this.getList()
            })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids
      this.$confirm('确定删除这条数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return api.delDiscount(ids)
      }).then((res) => {
        this.delAppointment(ids)
        this.delReserveMsg(ids)
        this.getList()
        this.$message({
          message: '删除成功',
          type: 'success'
        })
      }).catch(() => { })
    },
    // 预约人取消
    cancelbtu(row) {
      console.log(row);
      api.delRAppointment(row.id).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message({
            message: '取消成功',
            type: 'success'
          })
          this.getAppointmentList(row.healthDiscountId)
        } else {
          this.$message({
            message: '取消失败',
            type: 'error'
          })
        }
      })
    },
    // 预约完成
    completebtu(row) {
      row.status = 1
      api.updateAppointment(row).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '完成成功',
            type: 'success'
          })
          this.getAppointmentList(row.healthDiscountId)
        } else {
          this.$message({
            message: '完成失败',
            type: 'error'
          })
        }
      })
      this.getAppointmentList()
    },
    // 选择图片
    uploadImg(files) {
      let formData = new FormData();
      formData.append("file", files.file);
      this.uploadFile(formData); //上传头像
    },
    // 上传图片
    async uploadFile(formData) {
      try {
        let res = await uploadFile(formData);
        if (res.code == 200) {
          this.form.imageUrl = res.msg;
          this.$message({
            message: "上传成功",
            type: "success"
          });
        } else {
          this.$message({
            message: "上传失败",
            type: "error"
          });
        }
      } catch (error) {
        this.$message({
          message: "上传失败",
          type: "error"
        });
      }
    },
    // 详情表
    detailListdiolog(row) {
      this.appointmentList = []
      this.openDetailList = true
      this.detailListTitle = '活动预约详情'
      this.discountTitle = row.title
      this.discountDescription = row.description
      // 获取关系表
      this.getAppointmentList(row.id)
    },

    getAppointmentList(id) {
      api.getAppointment(id).then(res => {
        this.appointmentList = res.data
      })
      console.log(this.appointmentList)
    },

    // 获取社区列表
    async getCommuintyList(code) {
      const data = {
        code: code
      }
      const page = {
        pageSize: 99999,
        pageNum: 1
      }
      try {
        const res = await api.getCommunitySystemUser(data, page)
        if (res.code == 200) {
          this.communityList = res.rows
        }
      } catch (e) {

      }
    },
    //按活动id删除预约消息
    delAppointment(ids) {
      api.delAppointment(ids).then(res => {
        console.log(res);
      })
    },
    //按活动id删除活动消息提醒
    delReserveMsg(ids) {
      api.delReserveMsg(ids).then(res => {
        console.log(res);
      })
    }
  },
}
</script>

<style scope>
.el-pagination {
  text-align: center;
}

.input_box>span {
  font-size: 14px;
}


.activityContent {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
}

.reserveTitle {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
  display: block;
  object-fit: contain;
}
</style>
